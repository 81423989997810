import React, { useState, useContext } from 'react';
import '../../../styles/InfoWindowContent.css'; 
import { UserContext } from '../../../context/UserContext';
import AddReviewPanel from './AddReviewPanel'; 

const InfoWindowContent = ({ restaurant, onFileChange, onSave, onClose, uploadedFiles, setUploadedFiles, loading }) => {
  const [infoText, setInfoText] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);  
  const { loggedInUser } = useContext(UserContext);
  const [isReviewPanelOpen, setIsReviewPanelOpen] = useState(false);

  const nextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % restaurant.photos.length);
  };

  const previousPhoto = () => {
    setPhotoIndex(photoIndex === 0 ? restaurant.photos.length - 1 : photoIndex - 1);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
  };

  return (
    <div className="info-window">
      <button className="close-button" onClick={onClose}>x</button>
      <h2 className="info-name">{restaurant.name}</h2>
      {restaurant.photos && restaurant.photos.length > 0 && (
        <div className="photo-gallery">
          <button onClick={previousPhoto} className="gallery-button gallery-button-left">{"<"}</button>
          <img 
            src={restaurant.photos[photoIndex]} 
            alt={restaurant.name} 
            className="info-photo" 
          />
          <button onClick={nextPhoto} className="gallery-button gallery-button-right">{">"}</button>
        </div>
      )}
      <p className="info-address"><strong>Address:</strong> {restaurant.address || 'No address provided'}</p>
      <div className="add-review-button-container">
      <button className="add-review-button" onClick={() => setIsReviewPanelOpen(true)}>Add Review</button>
    </div>
      {isReviewPanelOpen && (
        <AddReviewPanel
          restaurant={restaurant}
          onClose={() => { setIsReviewPanelOpen(false); setUploadedFiles([]); }} 
          onFileChange={onFileChange}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          loading={loading}
          onSave2={onSave}
        />
      )}
    </div>
  );
};

export default InfoWindowContent;
