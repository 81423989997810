import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import zIndex from '@mui/material/styles/zIndex';

function SearchBarAll({ map, onPlaceSelected, setTemporaryMarker }) {
  const autocompleteRef = useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current && autocompleteRef.current.getPlace().geometry) {
      const place = autocompleteRef.current.getPlace();
      map.panTo(place.geometry.location);
      let zoomLevel = 12; 
      if (place.types.includes('restaurant')) {
        zoomLevel = 18; 
      }
      map.setZoom(zoomLevel);
      onPlaceSelected(place);
      setTemporaryMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
    }
  };

  return (
    <div>
      <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Search for address"



            
 
        />
      </Autocomplete>
    </div>
  );
}

export default SearchBarAll;