import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Grid, Box, useMediaQuery } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Switch from 'react-switch'; 
import { makeStyles } from '@mui/styles';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import { renderToString } from 'react-dom/server';

//Modal
import RestaurantInfoWindow from './RestaurantInfoWindow';
import SearchBarAll from './SearchBarAll'; 
import RatingFilter from './RatingFilter';
import AuthApp from './AuthApp';
import InfoWindowContent from './InfoWindowContent';

//Other
import { UserContext } from '../../../context/UserContext'; 
import { IN_DEV, localhost_server, path_server, fetchRestaurants } from '../../api.js';
import { imagesFrontPage, logoMarkers } from '../../../config/path';
import { GlobalStyle, Container, AppBarStyle, SidebarStyle, InfoContentEditMap, RestaurantWindowMainMap, SearchBarStyle} from './styleDesktop.js';
import { Navbar,  Logo, LogoImage, LogoText, ActionButton, LoginButton, LogoutButton } from '../../../styles/NavBarStyle.js';


const center = {
  lat: 59.911491,
  lng: 10.757933
};

const mainMapId = "32f8a5e3c0c9082";    
const GoogleMap_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


const MapComponent = () => {
  const { loggedInUser, setLoggedInUser  } = useContext(UserContext);
  const [userLocation, setUserLocation] = useState(center);
  const [userMarker, setUserMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [addnewplace, setAddNewPlace] = useState(false);
  const [showMainMap, setShowMainMap] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [infoText, setInfoText] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);  
  const [file, setFile] = useState(null);
  const [showAuth, setShowAuth] = useState(false);
  const [leftPaneWidth, setLeftPaneWidth] = useState(window.innerWidth * 0.35);  
  const [zoom, setZoom] = useState(15); 
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [temporaryMarker, setTemporaryMarker] = useState(null); 
  const [user, setUser] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [normalIcon, setNormalIcon] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [locationIcon, setLocationIcon] = useState(null);

  const handleNavigate = (path) => {
    navigate(path);
  };


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userPosition);
          setLocationIcon({
            url: logoMarkers.bluedot,
            scaledSize: new window.google.maps.Size(20, 20),
          });
        },
        (error) => console.error("Error while getting the localisation of the user.", error)
      );
    }
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); 
  };

const handleSwitchChange = () => {
  if(!loggedInUser){
    setShowAuth((prevShowAuth) => !prevShowAuth);
    setAddNewPlace(true);
  } else {
    handleNavigate('/add-new-place')
  }
};
  const handleSaveInfo = (reviewData) => {
    setLoading(true);

    const userId = loggedInUser ? loggedInUser.id : null;
    const formData = new FormData();
  
    // Append general restaurant information
    formData.append('name', selectedPOI.name || '');
    formData.append('location', JSON.stringify({ lat: selectedPOI.lat, lng: selectedPOI.lng, full_address: selectedPOI.address }));
    formData.append('generalInfos', JSON.stringify({
      phone: selectedPOI.phone || '',
      googleMapsUrl: selectedPOI.googleMapsUrl || '',
      price_range: selectedPOI.price_range || '',
      opening_hours: selectedPOI.opening_hours || [],
      website: selectedPOI.website || '',
      business_status: selectedPOI.business_status || '',
      rating: selectedPOI.rating || 0,
      user_ratings_total: selectedPOI.user_ratings_total || 0
    }));
  
    // Append review information
    formData.append('reviewText', reviewData.comment);
    formData.append('rating', reviewData.rating);
    formData.append('photoDate', reviewData.photoDate);
    formData.append('features', JSON.stringify(reviewData.features));
    formData.append('userId', userId);
  
    // Append uploaded files
    uploadedFiles.forEach(file => {
      formData.append('image', file);
    });
  
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/check-and-upload-restaurant`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log('Operation successful with Restaurant ID:', data.restaurant_id);
          setMessage('Information saved successfully!');
          setIsError(false);
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage('Error saving information. Please try again.');
        setIsError(true);
      })
      .finally(() => {
        setInfoText('');
        setSelectedPOI(null);
        setUploadedFiles([]);
        setLoading(false);
      });
  };
  
  const handleCloseWindow = () => {
    setInfoText('');
    setSelectedPOI(null);
    setTemporaryMarker(null); 
  }

  const handleGeolocationButtonClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userPosition);
          setUserMarker(userPosition);


          if (map) {
            map.panTo(userPosition);
            map.setZoom(15);
          }
        },
        () => {
          console.error("Error while getting the user's location.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleAuthButtonClick = () => {
    setShowAuth((prevShowAuth) => !prevShowAuth);
  };


  const Logout= () => {
    {
      console.log("User logged out");
      setLoggedInUser(null);
      localStorage.removeItem('loggedInUser'); 
    }
  };

  const handleMarkerClick = (restaurant) => {
    if (selectedRestaurant && selectedRestaurant._id === restaurant._id) {
      setSelectedRestaurant(null); 
    } else {
      setLoading(true)
      setSelectedRestaurant(restaurant); 
    }
  };
  
  const iconString = renderToString(<ArrowCircleDownOutlinedIcon style={{ fontSize: 16, color: 'black' }} />);
  
  // Encode the SVG string as a base64 data URL
  const iconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconString)}`;


  const onMapLoad = useCallback((map) => {
    setMap(map);
    setGoogleMapsLoaded(true);
    map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });
    map.addListener('click', (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({
          placeId: event.placeId,
          fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours', 'website',  'business_status', 'rating', 'user_ratings_total']
        }, (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedPOI({
              name: result.name,
              address: result.formatted_address,
              place_id: result.place_id,
              phone: result.formatted_phone_number,
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
              googleMapsUrl: result.url,
              price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
              opening_hours: result.opening_hours ? result.opening_hours.weekday_text : [],
              website: result.website ? result.website : "No website available",
              business_status: result.business_status ? result.business_status : "No business status available",
              rating: result.rating ? result.rating : "No ratings available",
              user_ratings_total: result.user_ratings_total ? result.user_ratings_total : "No user ratings total available",
            });
            setMessage('');
            setShowMessage(false);
            setTemporaryMarker(null); 
          }
        });
      }
    });
  }, []);

  const handlePlaceSelected = (place) => {
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId: place.place_id,
      fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours']
    }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPOI({
          name: result.name,
          address: result.formatted_address,
          place_id: result.place_id,
          phone: result.formatted_phone_number,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
          googleMapsUrl: result.url,
          price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
          opening_hours: result.opening_hours ? result.opening_hours.weekday_text : []
        });
        map.panTo(result.geometry.location);
        map.setZoom(15);
        setMessage('');
        setShowMessage(false);
      }
    });
  };

  const handleDeleteRestaurant = (restaurantId) => {
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/restaurant/${encodeURIComponent(restaurantId)}`, {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        setRestaurants((prevRestaurants) =>
          prevRestaurants.filter((restaurant) => restaurant._id.$oid !== restaurantId)
        );
        setSelectedRestaurant(null);
      } else {
        console.error('Failed to delete restaurant', data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    const handleResizeWindow = () => {
      setLeftPaneWidth(window.innerWidth * 0.35);
    };

    window.addEventListener('resize', handleResizeWindow);
    return () => window.removeEventListener('resize', handleResizeWindow);
  }, []);

  
  useEffect(() => {
    let timer;
    if (message) {
      setShowMessage(true);
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); 
    }
    return () => clearTimeout(timer); 
  }, [message]);

  useEffect(() => {
    if (showMainMap) {
      fetchRestaurants().then(setRestaurants).catch(error => {
        console.error('Error:', error);
        setRestaurants([]);
      });
    }
  }, [showMainMap]);

  useEffect(() => {
    if (googleMapsLoaded) {
      setNormalIcon({
        url: logoMarkers.BasicMarker,
        scaledSize: new window.google.maps.Size(60, 60),
      });
    
      setSelectedIcon({
        url: logoMarkers.SelectedMarker,
        scaledSize: new window.google.maps.Size(55, 55),
      });
    }
  }, [googleMapsLoaded]);
  

  useEffect(() => {
    async function fetchUserData() {
      try {
        const url = IN_DEV ? localhost_server : path_server;
        const response = await fetch(`${url}/api/check-login-status`, { credentials: 'include' });
        const data = await response.json();
        if (data.loggedIn) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, []);
  
  return (
    <>
      <GlobalStyle />
      <Container>
      <AppBar position="static" color="primary" style={AppBarStyle}>
        <Navbar>
          <Logo onClick={() => handleNavigate('/')}>
            <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
            <LogoText>Dining with Kids</LogoText>
          </Logo>
          {loggedInUser ? (
            <>
              <Typography
                variant="subtitle1"
                style={{  color: '#FFFFFF', fontWeight: 'bold', fontSize: 22, position:'absolute', marginLeft:'40%' }}
              >
                Welcome, {loggedInUser.firstName} {loggedInUser.lastName}!
              </Typography>
              <ActionButton onClick={handleSwitchChange}>
                Add New Place
              </ActionButton>
              <LogoutButton onClick={Logout}>
                Log Out
              </LogoutButton>
            </>
          ) : (
            <>
              <ActionButton onClick={handleSwitchChange}>
                Add New Place
              </ActionButton>

              <LoginButton onClick={handleAuthButtonClick}>
                Sign In
              </LoginButton>
            </>
          )}


        </Navbar>
      </AppBar>

        <Grid container direction={'row'}>
          <Grid item xs={12} md={12} style={{ width: '100vh', height: '100%' }}>
            {/* {WINDOW INFO CONTENT (EDIT MAP --> ADD NEW REVIEWS)} */}
            <div style={InfoContentEditMap(selectedPOI)}>
              {selectedPOI && loggedInUser && loggedInUser.role === 'admin' && (
                <InfoWindowContent
                  restaurant={selectedPOI}
                  onFileChange={handleFileChange}
                  onSave={handleSaveInfo}
                  onClose={handleCloseWindow}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  loading={loading}
                />
              )}
            </div>

            <Button
              variant="contained"
              color="grey"
              onClick={handleGeolocationButtonClick}
              style={{
                position: 'absolute',
                top: '15%',
                right: '10px',
                zIndex: '10',
                minWidth: 'auto', //
                padding: '10px',
              }}
            >
              <MyLocationIcon />
            </Button>

            {/* {WINDOW TO SEE THE COMMENT AND PICTURES POSTED} */}
            <div style={RestaurantWindowMainMap(selectedRestaurant)}>
              {selectedRestaurant && (
                <RestaurantInfoWindow
                  restaurant={selectedRestaurant}
                  onFileChange={handleFileChange}
                  onClose={() => setSelectedRestaurant(null)} 
                  onDelete={handleDeleteRestaurant}
                  onSave={handleSaveInfo}
                  loading={loading}
                  setLoading={setLoading}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              )}
              </div>
            <LoadScript googleMapsApiKey={GoogleMap_API_KEY} libraries={['places']}>
            <SearchBarStyle>
              {map && <SearchBarAll map={map} onPlaceSelected={handlePlaceSelected} setTemporaryMarker={setTemporaryMarker} />}
            </SearchBarStyle>
              <GoogleMap
                key={showMainMap ? 'custom-map' : 'default-map'}
                mapContainerStyle={{ height: '100vh', width: '100%' }}
                center={userLocation}
                zoom={zoom}
                onLoad={map => onMapLoad(map)}
                options={{
                  mapId: showMainMap ? mainMapId : null,
                  mapTypeControl: false, 
                  fullscreenControl: false,

                  streetViewControl: false, 
                  zoomControl: false, 
                  gestureHandling: 'greedy',
                }}
              >
                {googleMapsLoaded && showMainMap ? (
                  restaurants.filter(restaurant => !restaurant.IN_DEV).map(restaurant => (
                    <Marker
                      key={restaurant._id || restaurant.place_id}  
                      position={{ lat: restaurant.location.lat, lng: restaurant.location.lng }}
                      icon={selectedRestaurant && restaurant._id === selectedRestaurant._id ? selectedIcon: normalIcon}
                      label={zoom >= 14 ? {
                        text: restaurant.name,
                        color: "green",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        border: "1px solid #0056b3"
                      } : null}
                      onClick={() => handleMarkerClick(restaurant)}
                    />
                  ))
                ) : null}

                {googleMapsLoaded && showMainMap && path_server=="https://dev.diningwithkids.com" ? (
                  restaurants.filter(restaurant => restaurant.IN_DEV).map(restaurant => (
                    <Marker
                      key={restaurant._id || restaurant.place_id}  
                      position={{ lat: restaurant.location.lat, lng: restaurant.location.lng }}
                      icon={selectedRestaurant && restaurant._id === selectedRestaurant._id ? selectedIcon: normalIcon}
                      label={zoom >= 14 ? {
                        text: "ONLY FOR DEV",
                        color: "red",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        border: "1px solid #0056b3"
                      } : null}
                      onClick={() => handleMarkerClick(restaurant)}
                    />
                  ))
                ) : null}

              

                {userLocation && zoom >= 14 &&  (
                          <Marker
                            position={userLocation}
                            icon={locationIcon}
                          />
                        )}
                      
                    
                    

              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
      </Container>
      {/* {showMessage && <MessagePopup isError={isError} message={message} closePopup={() => setShowMessage(false)} />} */}
      {showAuth && <AuthApp handleClose={handleAuthButtonClick} addnewPlace={addnewplace} setAddNewPlace={setAddNewPlace}/>}

    </>
  );
}

export default MapComponent;