import styled from 'styled-components';

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5% 1%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;
  margin-right: 10px;
`;

const LogoText = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: #F5F5DC; 
`;

const ActionButton = styled.button`
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #357ABD;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 10%;
  margin-right: auto; // Distance entre ActionButton et Login/Logout Button
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #F5F5DC;
    transform: translateY(-2px);
  }
`;

const LoginButton = styled.button`
  padding: 15px 10px;
  font-size: 0.875rem;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 3%;
  
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #F5F5DC;
    color: black;
    transform: translateY(-2px);
  }
`;

const LogoutButton = styled.button`
  padding: 15px 10px;
  font-size: 0.875rem;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 3%;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #F5F5DC;
    color: black;
    transform: translateY(-2px);
  }
`;

export { Navbar, Logo, LogoImage, LogoText, ActionButton, LoginButton, LogoutButton };
