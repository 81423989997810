import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { imagesFrontPage } from '../../config/path';
import  { GlobalStyle, Container, Navbar, Logo, LogoImage, LogoText, MainContent, Footer}from '../../styles/NavBarStyleMobile';

const NavLinks = styled.div`
  display: flex;
  gap: 10px; // Reduced gap for mobile
`;

const Heading = styled.h2`
  font-size: 2.5rem; // Adjusted for mobile
  margin-bottom: 15px; // Adjusted margin
  color: #F5F5DC;
`;

const Description = styled.p`
  font-size: 1rem; // Adjusted for readability on mobile
  line-height: 1.6;
`;

const ActionButton = styled.button`
  padding: 10px 20px; // Larger for touch accessibility
  font-size: 0.7rem; // Adjusted size
  background-color: #357ABD;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #F5F5DC;
    transform: translateY(-2px);
  }
  color: black;
`;

const ActionButtonExplore = styled.button`
  padding: 12px 24px; // Larger for touch accessibility
  font-size: 1rem; // Adjusted size
  background-color: #357ABD;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #F5F5DC;
    transform: translateY(-2px);
  };
  margin-top:20px;
  color: black;
`;


const WelcomeComponentMobile = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar>
          <Logo onClick={() => handleNavigate('/')}>
            <LogoImage src={imagesFrontPage.logo} alt="Logo" />
            <LogoText>Dining with Kids</LogoText>
          </Logo>
          <NavLinks>
            <ActionButton onClick={() => handleNavigate('/contact')}>Contact Us</ActionButton>
            <ActionButton onClick={() => window.open("https://www.termsfeed.com/live/effda6a1-c463-4bf0-af0b-dd100a3cb6c1", "_blank", "noopener noreferrer")}>
              Privacy Policy
            </ActionButton>
          </NavLinks>
        </Navbar>
        <MainContent>
          <Heading>Welcome to Dining with Kids!</Heading>
          <Description>
            Discover and share your favorite family-friendly restaurants with play areas. Join our community of parents who make dining out fun and easy!
          </Description>
          <ActionButtonExplore onClick={() => handleNavigate('/map')}>Explore Map</ActionButtonExplore>
        </MainContent>
        <Footer>
          © {new Date().getFullYear()} Dining with Kids. All rights reserved.
        </Footer>
      </Container>
    </>
  );
};

export default WelcomeComponentMobile;
