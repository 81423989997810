import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Grid, Button} from '@mui/material';
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import MyLocationIcon from '@mui/icons-material/MyLocation';

//Modal
import SearchBarAll from './SearchBarAll'; 
import InfoWindowContent from './InfoWindowContent';
import AddNewPlaceOwner from './AddNewPlaceOwner';

//Other
import { UserContext } from '../../../context/UserContext'; 
import { IN_DEV, localhost_server, path_server, fetchRestaurants } from '../../api.js';
import { imagesFrontPage, logoMarkers} from '../../../config/path';
import { GlobalStyle, Container, AppBarStyle, SearchBarStyle, WidgetContainerInfo, WidgetContentInfo} from './styleMobile.js';
import  {Navbar,  Logo, LogoImage, LogoText, ActionButtonHowItWorks, LogoTextBack} from '../../../styles/NavBarStyleMobile';
import './styleAnimations.css'; 
import { FaArrowLeft } from 'react-icons/fa';



const GoogleMap_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const center = {
  lat: 59.911491,
  lng: 10.757933
};


const MapComponent = ({isAddNewPlaceOpen, setIsAddNewPlaceOpen}) => {
  const { loggedInUser } = useContext(UserContext);

  const [userLocation, setUserLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [infoText, setInfoText] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);  
  const [file, setFile] = useState(null);
  const [leftPaneWidth, setLeftPaneWidth] = useState(window.innerWidth * 0.35);  
  const [zoom, setZoom] = useState(15); 
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(false);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const [step, setStep] = useState(0);
  const [howitworks, setHowItWorks] = useState(false);
  const [totrefresh, setTotRefresh] = useState(false);
  const [locationIcon, setLocationIcon] = useState(null);

  const Instruction_step1 = ({ text }) => (
    <div className="instruction-container">
      <div className="arrow"></div>
      <div className="text">{text}</div>
    </div>
  );

  const Instruction_step2 = ({ text}) => (
    <div className="instruction-container-step2">
      <div className="text">{text}</div>
    </div>
  );

  const Instruction_step3 = ({ text}) => (
    <div className="instruction-container-step3">
      <div className="text">{text}</div>
    </div>
  );

  
  const handleNavigate = (path) => {
    navigate(path);
  };
  
  const handleGeolocationButtonClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userPosition);
  
          if (map) {
            map.panTo(userPosition);
            map.setZoom(15);
          }
        },
        () => {
          console.error("Error while getting the user's location.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position)
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLocationIcon({
            url: logoMarkers.bluedot,
            scaledSize: new window.google.maps.Size(10, 10),
          });
        },
        () => {
          console.error("Error while getting the localisation of the user.");
        }
      );
    }
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); 
  };

  const handleSaveInfo = (reviewData) => {
    setLoadingImg(true);

    const userId = loggedInUser ? loggedInUser.id : null;
    const formData = new FormData();
  
    // Append general restaurant information
    formData.append('name', selectedPOI.name);
    formData.append('location', JSON.stringify({ lat: selectedPOI.lat, lng: selectedPOI.lng, full_address: selectedPOI.address }));
    formData.append('generalInfos', JSON.stringify({
      phone: selectedPOI.phone,
      googleMapsUrl: selectedPOI.googleMapsUrl,
      price_range: selectedPOI.price_range,
      opening_hours: selectedPOI.opening_hours,
      website: selectedPOI.website,
      business_status: selectedPOI.business_status,
      rating_google_maps: selectedPOI.rating,
      user_ratings_google_maps_total: selectedPOI.user_ratings_total
    }));
  
    // Append review information
    formData.append('reviewText', reviewData.comment);
    formData.append('rating', reviewData.rating);
    formData.append('photoDate', reviewData.photoDate);
    formData.append('features', JSON.stringify(reviewData.features));
    formData.append('userId', userId);
  
    // Append uploaded files
    uploadedFiles.forEach(file => {
      formData.append('image', file);
    });
  
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/check-and-upload-restaurant`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log('Operation successful with Restaurant ID:', data.restaurant_id);
          setMessage('Information saved successfully!');
          setIsError(false);
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage('Error saving information. Please try again.');
        setIsError(true);
      })
      .finally(() => {
        setInfoText('');
        setSelectedPOI(null);
        setUploadedFiles([]);
        setTotRefresh(true);
        setHowItWorks(false);
        setStep(0);
        setLoadingImg(false);
        alert('New place added succesfully!');
      });
  };
  
  const handleCloseWindow = () => {
    setInfoText('');
    setSelectedPOI(null); 
  }

  const handleHowItWorksButton = () => {
    if(howitworks){
      setTotRefresh(true);
      setHowItWorks(false);
      setStep(0);
      setSelectedPOI(null);
    } else {
      setTotRefresh(false);
      setHowItWorks(true);
      setStep(1);
    }
  };

  const handleSaveInfoOwner = (reviewData) => {
    setLoadingImg(true);
    const userId = loggedInUser ? loggedInUser.id : null;
    
    const formData = new FormData();
    formData.append('reviewText', reviewData.comment);
    formData.append('rating', reviewData.rating);
    formData.append('photoDate', reviewData.photoDate);
    formData.append('features', JSON.stringify(reviewData.features));
    formData.append('userId', userId);
    formData.append('restaurantId', reviewData.restaurantId);
    uploadedFiles.forEach(file => {
      formData.append('image', file);
    });
    uploadedLogo.forEach(file => {
      formData.append('logo', file);
    });
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/add-new-review`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setMessage('Information saved successfully!');
          setIsError(false);
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage('Error saving information. Please try again.');
        setIsError(true);
      })
      .finally(() => {
        setInfoText('');
        setUploadedFiles([]);
        setLoadingImg(false);
        setIsAddNewPlaceOpen(false);
        alert('New place added succesfully!');
      });
  };

  const onMapLoad = useCallback((map) => {
    setMap(map);
    setGoogleMapsLoaded(true);
    map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });
    map.addListener('click', (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({
          placeId: event.placeId,
          fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours', 'website',  'business_status', 'rating', 'user_ratings_total'],
          language: 'en'
        }, (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedPOI({
              name: result.name,
              address: result.formatted_address,
              place_id: result.place_id,
              phone: result.formatted_phone_number,
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
              googleMapsUrl: result.url,
              price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
              opening_hours: result.opening_hours ? result.opening_hours.weekday_text : [],
              website: result.website ? result.website : "No website available",
              business_status: result.business_status ? result.business_status : "No business status available",
              rating: result.rating ? result.rating : "No ratings available",
              user_ratings_total: result.user_ratings_total ? result.user_ratings_total : "No user ratings total available",
            });
            setMessage('');
            setShowMessage(false);
          }
        });
      }
    });
  }, []);

  const handlePlaceSelected = (place) => {
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId: place.place_id,
      fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours', 'website',  'business_status', 'rating', 'user_ratings_total'],
      language: 'en'
    }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPOI({
          name: result.name,
          address: result.formatted_address,
          place_id: result.place_id,
          phone: result.formatted_phone_number,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
          googleMapsUrl: result.url,
          price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
          opening_hours: result.opening_hours ? result.opening_hours.weekday_text : [],
          website: result.website ? result.website : "No website available",
          business_status: result.business_status ? result.business_status : "No business status available",
          rating: result.rating ? result.rating : "No ratings available",
          user_ratings_total: result.user_ratings_total ? result.user_ratings_total : "No user ratings total available",
        });
        map.panTo(result.geometry.location);
        map.setZoom(15);
        setMessage('');
        setShowMessage(false);
      }
    })
  };

  useEffect(() => {
    const handleResizeWindow = () => {
      setLeftPaneWidth(window.innerWidth * 0.35);
    };

    window.addEventListener('resize', handleResizeWindow);
    return () => window.removeEventListener('resize', handleResizeWindow);
  }, []);

  
  useEffect(() => {
    let timer;
    if (message) {
      setShowMessage(true);
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); 
    }
    return () => clearTimeout(timer); 
  }, [message]);
  
  if  (isAddNewPlaceOpen) {
    return(
    <AddNewPlaceOwner
    
    onFileChange={handleFileChange}
    onSave2={handleSaveInfoOwner}
    uploadedFiles={uploadedFiles}
    setUploadedFiles={setUploadedFiles}
    uploadedLogo={uploadedLogo}
    setUploadedLogo={setUploadedLogo}
    loadingImg={loadingImg}

    />)
  }

  return (
    <>
      {loggedInUser ? (
        <>
          <GlobalStyle />
          <Container>
            <AppBar position="static" color="primary" style={AppBarStyle}>
              <Navbar>
                <Logo onClick={() => handleNavigate('/map')}>
                  <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
                  <LogoTextBack>
                    <FaArrowLeft className="icon" size={20} />
                    Explore map
                  </LogoTextBack>
                </Logo>
                <ActionButtonHowItWorks isActive={howitworks} onClick={handleHowItWorksButton}>
                {howitworks ? 'Stop Tutorial' : 'Guide Me'}
              </ActionButtonHowItWorks>
              </Navbar>
              {step === 1 && <div className="overlay_step1"> <Instruction_step1 text="1: Enter your restaurant name and select the correct one in the list" /> </div>}
            {step === 2 && <Instruction_step2 text="2: Click on the restaurant on the map"/>}
            {step === 3 && <div className="overlay_step3"> <Instruction_step3 text="3: Press on 'Add Review' and fill in the form to add a review"/> </div>}
            </AppBar>
            <Grid container direction={'row'}>
              <Grid item xs={12} md={12} style={{ width: '100vh', height: '100%' }}>

              <Button
              variant="contained"
              color="grey"
              onClick={handleGeolocationButtonClick}
              style={{
                position: 'absolute',
                top: '15%',
                right: '10px',
                zIndex: '10',
                minWidth: 'auto', // Pour que le bouton prenne la taille de l'icône
                padding: '10px',
              }}
            >
              <MyLocationIcon />
            </Button>

                {selectedPOI && loggedInUser && (
                  <WidgetContainerInfo expanded={expanded}>
                    <WidgetContentInfo>
                      <InfoWindowContent
                        restaurant={selectedPOI}
                        onFileChange={handleFileChange}
                        onSave={handleSaveInfo}
                        onClose={handleCloseWindow}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        loadingImg={loadingImg}
                        setStep={setStep}
                        howItWorks={howitworks}
                        setHowItWorks={setHowItWorks}
                        totRefresh={handleHowItWorksButton}
                      />
                    </WidgetContentInfo>
                  </WidgetContainerInfo>
                )}
                <LoadScript googleMapsApiKey={GoogleMap_API_KEY} libraries={['places']} language='en'>
                  <SearchBarStyle>
                    {map && <SearchBarAll 
                      map={map} 
                      onPlaceSelected={handlePlaceSelected} 
                      howItWorks={howitworks} 
                      setStep={setStep}
                      totRefresh = {totrefresh}
                    />}
                  </SearchBarStyle>
                  <GoogleMap
                    key={'default-map'}
                    mapContainerStyle={{ height: '100vh', width: '100%' }}
                    center={userLocation? userLocation: center}
                    zoom={zoom}
                    onLoad={map => onMapLoad(map)}
                    options={{
                      mapId: null,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      streetViewControl: false,
                      zoomControl: false,
                      gestureHandling: 'greedy',
                    }}
                  >
                    {userLocation && zoom >= 14 &&  (
          <Marker
            position={userLocation}
            icon={locationIcon}
          />
        )}
                  </GoogleMap>
                </LoadScript>
              </Grid>
            </Grid>
            
          </Container>
        </>
      ) : (
        handleNavigate('/map')
      )}
    </>
  );
}

export default MapComponent;