import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import CloseIcon from '@mui/icons-material/Close';
import '../../../styles/AuthApp.css';
import { useAuth } from '../../../hooks/useAuth'; 
import { logoGoogleFacebook } from '../../../config/path';
import { IN_DEV, localhost_server, path_server } from '../../api.js';

const AuthApp = ({ handleClose, addnewPlace, setAddNewPlace, addreviewpanel, setAddReviewPanel, setIsReviewPanelOpen }) => {
  const navigate = useNavigate();
  const { setLoggedInUser } = useContext(UserContext);
  const [panelActive, setPanelActive] = useState(false); 
  const [formData, setFormData] = useState({
    signInEmail: '',
    signInPassword: '',
    firstName: '',
    lastName: '',
    registerEmail: '',
    registerPassword: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleCloseSignIn = () => {
    setPanelActive(false); 
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const { loginWithGoogle, loginWithFacebook } = useAuth();

  const baseUrl = IN_DEV ? localhost_server : path_server;

  const signIn = (event) => {
    event.preventDefault();
    const { signInEmail, signInPassword } = formData;
    fetch(`${baseUrl}/api/signin`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email: signInEmail, password: signInPassword })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setLoggedInUser(data.user);
        localStorage.setItem('loggedInUser', JSON.stringify(data.user)); // Stocke l'utilisateur dans localStorage
        if (addnewPlace) {
          handleNavigate('/add-new-place');
          setAddNewPlace(false);
        }
        if (addreviewpanel) {
          setAddReviewPanel(false);
          setIsReviewPanelOpen(true);
        }
        handleClose();
      } else {
        alert('Error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const register = (event) => {
    event.preventDefault();
    const { firstName, lastName, registerEmail, registerPassword, confirmPassword } = formData;
    if (registerPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    fetch(`${baseUrl}/api/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName, lastName, email: registerEmail, password: registerPassword })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setLoggedInUser(data.user);
        localStorage.setItem('loggedInUser', JSON.stringify(data.user)); // Stocke l'utilisateur dans localStorage
        alert('Registered successfully!');
        handleClose();
      } else {
        alert('Error: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <div className={`container ${panelActive ? 'right-panel-active' : ''}`} id="container">
      <div className="form-container">
        {panelActive ? (
          // Register form
          <div className="register-container">
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />
            <form onSubmit={register}>
                <h1>Create Account</h1>
                <input type="text" id="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                <input type="text" id="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                <input type="email" id="registerEmail" placeholder="Email" value={formData.registerEmail} onChange={handleChange} required />
                <input type="password" id="registerPassword" placeholder="Password" value={formData.registerPassword} onChange={handleChange} required />
                <input type="password" id="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />
                <button type="submit" className="button">Register</button>
                <button type="button" className="button" onClick={handleCloseSignIn}>Already have an account? Sign In</button>
            </form>
          </div>
        ) : (
          // Sign-in form
          <div className="sign-in-container">
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} />
            <form onSubmit={signIn}>
              {addreviewpanel ? (
                <h1>Sign In to add a review</h1>
              ) : (
                <h1>Sign In</h1>
              )}
                <input type="email" id="signInEmail" placeholder="Email" value={formData.signInEmail} onChange={handleChange} required />
                <input type="password" id="signInPassword" placeholder="Password" value={formData.signInPassword} onChange={handleChange} required />
                <button type="submit" className="button">Sign In</button>
                <button type="button" className="button" onClick={() => setPanelActive(true)}>Need an account? Register</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthApp;