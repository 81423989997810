import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from '@mui/material/Modal';
import '../../../styles/RestaurantInfoWindowMobile.css';
import { IN_DEV, localhost_server, path_server } from '../../api.js';
import { UserContext } from '../../../context/UserContext';
import AddReviewPanel from './AddReviewPanel'; 
import AuthApp from './AuthApp'; 
import MapIcon from '@mui/icons-material/Map';
import DeleteIcon from '@mui/icons-material/Delete';
import ReviewsIcon from '@mui/icons-material/RateReview';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const RestaurantInfoWindow = ({ restaurant, onDelete, loading, setLoading, loadingImg, isModalOpen, setIsModalOpen, onClose, setUploadedFiles, onFileChange, uploadedFiles, onSave }) => {
  const [comments, setComments] = useState([]);
  const [restaurant_details, setRestaurantDetails] = useState({});
  const [activeTab, setActiveTab] = useState('comments');
  const [isReviewPanelOpen, setIsReviewPanelOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
  const { loggedInUser } = useContext(UserContext);
  const [showAuth, setShowAuth] = useState(false);
  const [addReviewPanel, setAddReviewPanel] = useState(false);
  const modalContentRef = useRef(null);
  const infoHeaderRef = useRef(null);
  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);
  const touchStartTimeRef = useRef(0);
  const buttonGroupRef = useRef(null);
  
  useEffect(() => {
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/comments_and_images/${encodeURIComponent(restaurant._id.$oid)}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === "success" && Array.isArray(data.comments_and_images)) {
          const comments = data.comments_and_images.map(comment => ({
            text: comment.text,
            authorName: comment.author_name,
            date: comment.date,
            imageUrls: comment.image_urls || [],
            owner: comment.owner,
            id:comment.id,
            isExpanded: false
          }));
          const sortedComments = sortComments(comments);
          setComments(sortedComments);
          setRestaurantDetails(data.restaurant_details || {});
          setLoading(false);
        } else {
          console.error('Failed to fetch comments and images or data format incorrect', data);
          setComments([]);
        }
      })
      .catch(error => {
        console.error('Error fetching comments and images:', error);
        setComments([]);
      });
  }, [restaurant._id, setLoading]);

  const sortComments = (comments) => {
    return comments.sort((a, b) => b.owner - a.owner);
  };

  const handleScroll = (event) => {
    event.stopPropagation();
    const element = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = element;
    const maxScrollTop = scrollHeight - clientHeight; 
  
    let lastScrollTop = element.dataset.lastScrollTop || 0;
    const scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';
    element.dataset.lastScrollTop = scrollTop;
  
    if (scrollTop <= 0 && scrollDirection === 'up') {
      event.preventDefault();
      applyBounceEffect(element, 'translateY(10px)');
    } else if (scrollTop >= maxScrollTop && scrollDirection === 'down') {
      event.preventDefault();
      applyBounceEffect(element, 'translateY(-10px)');
    }
  };
  
  function applyBounceEffect(element, transformValue) {
    element.style.transition = 'transform 0.2s ease-out';
    element.style.transform = transformValue;
    setTimeout(() => {
      element.style.transform = 'translateY(0px)';
    }, 200);
  }

  useEffect(() => {
    const modalContent = modalContentRef.current;
    if (modalContent) {
      modalContent.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (modalContent) {
        modalContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const openModal = (commentIndex, imageIndex) => {
    setCurrentCommentIndex(commentIndex);
    setCurrentImageIndex(imageIndex);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showPreviousImage = () => {
    const numImages = comments[currentCommentIndex].imageUrls.length;
    setCurrentImageIndex(prev => (prev - 1 + numImages) % numImages); 
  };

  const showNextImage = () => {
    const numImages = comments[currentCommentIndex].imageUrls.length;
    setCurrentImageIndex(prev => (prev + 1) % numImages);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        showNextImage();
      } else if (event.key === 'ArrowLeft') {
        showPreviousImage();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, currentCommentIndex, comments]);

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this restaurant?")) {
      const url = IN_DEV ? localhost_server : path_server;
      fetch(`${url}/api/restaurant/${encodeURIComponent(restaurant._id.$oid)}`, {
        method: 'DELETE'
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            onDelete(restaurant._id.$oid);
          } else {
            console.error('Failed to delete restaurant', data);
          }
        });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No date available';
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getGalleryItems = () => {
    const galleryItems = comments.map(comment => ({
      imageUrls: comment.imageUrls || [],
      comment: comment.text || 'No comment available',
      author: comment.authorName || 'Unknown author',
      date: comment.date ? formatDate(comment.date) : 'No date available',
      owner: comment.owner,
      id: comment.id,
      isExpanded: comment.isExpanded
    }));
    return galleryItems;
  };

  const handleAddClick = () => {
    if (!loggedInUser) {
      setShowAuth(true);
      setAddReviewPanel(true);
    } else {
      setIsReviewPanelOpen(true);
    }
  };

  const handleToggleExpand = (index) => {
    const updatedComments = comments.map((comment, i) => {
      if (i === index) {
        return { ...comment, isExpanded: !comment.isExpanded };
      }
      return comment;
    });
    setComments(updatedComments);
  };
  
  const handleTouchStart = (event) => {
    touchStartXRef.current = event.touches[0].clientX;
    touchStartTimeRef.current = new Date().getTime();
  };

  const handleTouchMove = (event) => {
    touchEndXRef.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchStartX = touchStartXRef.current;
    const touchEndX = touchEndXRef.current;
    const touchDuration = new Date().getTime() - touchStartTimeRef.current;
    
    if (touchDuration > 50 && Math.abs(touchStartX - touchEndX) > 3) {
      if (touchStartX - touchEndX > 3) {
        showNextImage();
      } else if (touchEndX - touchStartX > 3) {
        showPreviousImage();
      }
    }
  };

  if (loading) {
    return <div className="spinner"></div>; 
  }

  return (
    <>
      <div className='title-restau-container' ref={infoHeaderRef}>
        <div className="info-header">
          <h2>{restaurant.name}</h2>
        </div>
       
        <div className="button-group" ref={buttonGroupRef} >
          <a onClick={() => setActiveTab('comments')} className={`icon-button ${activeTab === 'comments' ? 'active' : ''}`}>
            <ReviewsIcon />
          </a>
          <a onClick={() => setActiveTab('location')} className={`icon-button ${activeTab === 'location' ? 'active' : ''}`}>
            <InfoIcon />
          </a>
          <a onClick={handleAddClick} className="icon-button">
            <AddCircleOutlineIcon />
          </a>
          <a href={restaurant_details.generalInfos?.googleMapsUrl} target="_blank" rel="noopener noreferrer" className="icon-button">
            <MapIcon />
          </a>
          {loggedInUser && loggedInUser.role === 'admin' && (
  <a
    onClick={() => {
      if (window.confirm("Are you sure you want to delete this restaurant?")) {
        onDelete(restaurant._id.$oid);
      }
    }}
    className="icon-button delete"
  >
    <DeleteIcon />
  </a>
)}
        </div>
      </div>

      <div className="content" ref={modalContentRef}>
  {activeTab === 'comments' && (
    <div className="image-gallery">
      {getGalleryItems().map((item, index) => (
        <div key={index} className={`gallery-item ${item.owner ? 'owner-comment' : ''}`}>
          {item.owner && (
            <div className="owner-comment-label">Comment by the owner</div>
          )}
          {item.imageUrls.length > 0 && (
            <>
              {item.imageUrls.length === 1 ? (
                <img
                  src={item.imageUrls[0]}
                  alt={`Image 1 of ${item.author}`}
                  className="gallery-image-full"
                  onClick={() => openModal(index, 0)}
                />
              ) : (
                <div className="gallery-large-small-container">
                  <img
                    src={item.imageUrls[0]}
                    alt={`Image 1 of ${item.author}`}
                    className="gallery-image-large"
                    onClick={() => openModal(index, 0)}
                  />
                  <div className="gallery-image-small-container">
                    {item.imageUrls.slice(1, 3).map((url, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={url}
                        alt={`Image ${imgIndex + 2} of ${item.author}`}
                        className="gallery-image-small"
                        onClick={() => openModal(index, imgIndex + 1)}
                      />
                    ))}
                    {item.imageUrls.length > 3 && (
                      <div className="overlay-more" onClick={() => openModal(index, 3)}>
                        +{item.imageUrls.length - 3}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="comment-section">
            <div className={`comment-text ${item.isExpanded ? 'expanded' : ''}`}>
              {item.comment.length > 500 && !item.isExpanded ? `${item.comment.substring(0, 500)}...` : item.comment}
            </div>
            {item.comment.length > 500 && (
              <span className="show-more" onClick={() => handleToggleExpand(index)}>
                {item.isExpanded ? 'show less' : 'show more'}
              </span>
            )}
            <div className="comment-author">
              <span className="author-name">~ {item.author}</span> 
              <span className="posted-date">  {item.date}</span>
            </div>
           
            {/* Bouton de suppression si l'utilisateur est connecté et est l'auteur du commentaire */}
            
            
            {/* {loggedInUser && loggedInUser.id === item.id && (
              
              <a onClick={() => onDelete(restaurant._id.$oid)} className="icon-button delete">
              <DeleteIcon />
            </a>
            )} */}
          </div>
        </div>
      ))}
    </div>
  )}
  {activeTab === 'location' && restaurant_details && (
    <div>
      <p className="info-address">Address: {restaurant_details.location.full_address || 'No address provided'}</p>
      <p className="info-phone">Phone Number: {restaurant_details.generalInfos.phone || 'No phone number provided'}</p>
      <p className="info-price-range">Price Range: {restaurant_details.generalInfos.price_range || 'No price range provided'}</p>
      <p className="info-website">Website: {restaurant_details.generalInfos.website || 'No website provided'}</p>
      {restaurant_details.generalInfos.opening_hours && (
        <>
          <p className="info-opening-hours-title">Opening Hours</p>
          <ul className="info-opening-hours">
            {restaurant_details.generalInfos.opening_hours.map((hour, index) => (
              <li key={index}>{hour}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  )}
</div>

      <Modal open={isModalOpen} onClose={closeModal}>
        <div className="modal-content-new">
          <button className="closing-modal-button" onClick={closeModal}>x</button>
          {comments.length > currentCommentIndex && comments[currentCommentIndex].imageUrls.length > 0 && (
            <div className="image-container">
              <div className="image-wrapper">
                <img
                  src={comments[currentCommentIndex].imageUrls[currentImageIndex]}
                  alt="Restaurant"
                  className="modal-image"
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                />
                <div className="comment-overlay">
                  <p className={`comment-text-modal ${comments[currentCommentIndex].isExpanded ? 'expanded' : ''}`}>
                    {comments[currentCommentIndex].text.length > 500 && !comments[currentCommentIndex].isExpanded
                      ? `${comments[currentCommentIndex].text.substring(0, 500)}...`
                      : comments[currentCommentIndex].text}
                  </p>
                  {comments[currentCommentIndex].text.length > 500 && (
                    <span className="show-more" onClick={() => handleToggleExpand(currentCommentIndex)}>
                      {comments[currentCommentIndex].isExpanded ? 'Show less' : 'Show more'}
                    </span>
                  )}
                  <div className="comment-author">
                    <span className="author-name"> ~ {comments[currentCommentIndex].authorName}</span>
                    <span className="comment-date">{comments[currentCommentIndex].date ? formatDate(comments[currentCommentIndex].date) : 'No date available'}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      {isReviewPanelOpen && loggedInUser && (
        <AddReviewPanel
          restaurant={restaurant}
          onClose={() => { setIsReviewPanelOpen(false); setUploadedFiles([]); }}
          onFileChange={onFileChange}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          loadingImg={loadingImg}
          onSave2={onSave}
        />
      )}
      {showAuth && <AuthApp handleClose={() => setShowAuth(false)} addReviewPanel={addReviewPanel} setAddReviewPanel={setAddReviewPanel} setIsReviewPanelOpen={setIsReviewPanelOpen} />}
    </>
  );
};

export default RestaurantInfoWindow;