import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

export const IN_DEV = false;
export const localhost_server = "http://127.0.0.1:5000";
export const path_server = "https://diningwithkids.com";
export const fetchRestaurants = async () => {
  try {
    const response = await fetch(IN_DEV ? `${localhost_server}/api/get-restaurant-info` : `${path_server}/api/get-restaurant-info`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.status === "success") {
      return JSON.parse(data.data);
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error("Error fetching restaurant data:", error);
    throw error;
  }
};

export const useCheckLoginStatus = () => {
  const { setLoggedInUser } = useContext(UserContext);
  const navigate = useNavigate(); 

  const checkLoginStatus = () => {
    fetch(IN_DEV ? `${localhost_server}/api/check-login-status` : `${path_server}/api/check-login-status`, {
      method: 'GET', 
      credentials: 'include' 
    })
    .then(response => response.json())
    .then(data => {
      if (data.loggedIn) {
        console.log("User connected");
        setLoggedInUser(data.user);
        //navigate('/home'); // Example of possible navigation
      } else {
        console.log("User NOT connected");
        //navigate('/login'); // Navigate to login if not logged in
      }
    })
    .catch(error => {
      console.error('Error checking login status:', error);
      alert('Error checking login status. Please check console for details.');
    });
  };

  return { checkLoginStatus }; // Return it so it can be called within components
};