import styled, { createGlobalStyle } from 'styled-components';
import { AppBar, Typography, Switch, Button } from '@mui/material';
import { imagesFrontPage } from '../config/path';


const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background: url(${imagesFrontPage.background}) center center / cover no-repeat fixed;
`;

const Navbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5% 1%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;
  margin-right: 10px;
`;

const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: #F5F5DC; 
`;

const MainContent = styled.div`
  text-align: center;
  color: #fff;
  padding: 20px; // Adjusted padding
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  justify: center;
  display: flex;
  flex-direction: column;
  justify-content: center;  // Centre les enfants verticalement
  align-items: center;      // Centre les enfants horizontalement
  position: fixed;
  margin-top: 50%;
`;

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const ActionButton = styled.button`
 padding: 5px 10px; // Taille plus petite
  font-size: 1rem; // Adjusted size
  background-color: #357ABD;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #F5F5DC;
    transform: translateY(-2px);
  }
`;

const LoginButton = styled.button`
  padding: 5px 10px; // Taille plus petite
  font-size: 0.875rem; // Taille ajustée
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px; // Espace entre les boutons
  transition: all 0.3s ease;
  &:hover {
    background-color: #F5F5DC;
    color: black;
    transform: translateY(-2px);
  }
`;

const LogoutButton = styled.button`
  padding: 5px 10px; // Taille plus petite
  font-size: 0.875rem; // Taille ajustée
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px; // Espace entre les boutons
  transition: all 0.3s ease;
  &:hover {
    background-color: #F5F5DC;
    color: black;
    transform: translateY(-2px);
  }
`;

const ActionButtonHowItWorks = styled.button`
  padding: 10px 20px; // Larger for touch accessibility
  font-size: 1rem; // Adjusted size
  background-color: ${(props) => (props.isActive ? 'red' : 'gold')}; /* Rouge si actif, doré sinon */
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
  }
`;

const SwitchContainer = styled.div`
  position: absolute;
  top: 12%;
  right: 1.5%;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  z-index: 3;
`;

const LogoTextBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #F5F5DC;
  font-weight: bold;
  font-size: 18px; // Taille de police plus grande pour une meilleure lisibilité sur mobile
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3; // Effet de survol pour les dispositifs non tactiles
  }

  .icon {
    margin-right: 10px; // Espace entre l'icône et le texte
  }
`;

export { GlobalStyle, Container, Navbar, Logo, LogoImage, LogoText, MainContent, Footer, ActionButton, SwitchContainer, ActionButtonHowItWorks, LogoTextBack, LoginButton, LogoutButton};
