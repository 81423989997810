import React, { useState, useContext } from 'react';
import { TextField, Checkbox, FormControlLabel, Rating, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../../context/UserContext';
import '../../../styles/AddReviewPanel.css'; 

const AddReviewPanel = ({ restaurant, onFileChange, onSave2, onClose, uploadedFiles, setUploadedFiles, loading }) => {
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [photoDate, setPhotoDate] = useState('');
  const [filter, setFilter] = useState('');
  const [infoText, setInfoText] = useState('');
  const [features, setFeatures] = useState({
    highChairs: false,
    games: false,
    kidsMenu: false,
    changingStations: false,
    indoorPlayingArea: false,
    outdoorPlayingArea: false,
  });
  const { loggedInUser } = useContext(UserContext);
  console.log(loggedInUser)

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
  };

  const handleFeatureChange = (event) => {
    setFeatures({ ...features, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    const reviewData = {
      comment,
      rating,
      photoDate,
      features,
    };
    onSave2(reviewData);
  };

  return (
    <div className="review-panel-container">
      <button className="close-button-new" onClick={onClose}>x</button>
      <h2 className="review-panel-title">Adding review - {restaurant.name}</h2>
      <div className="review-section">
        <TextField
          label="Write your comment..."
          value={comment}
          onChange={e => setComment(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
        />
        <div className="rating-section">
          <h3>Rating:</h3>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
            size="large"
          />
        </div>
        <TextField
          label="Date of Photos"
          type="date"
          value={photoDate}
          onChange={e => setPhotoDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
          
            <h3>Features (optional):</h3>
            <div className="features-section">
            {["highChairs", "games", "kidsMenu", "changingStations", "indoorPlayingArea", "outdoorPlayingArea"].map(feature => (
              <FormControlLabel
                key={feature}
                control={<Checkbox checked={features[feature]} onChange={handleFeatureChange} name={feature} />}
                label={feature.charAt(0).toUpperCase() + feature.slice(1).replace(/([A-Z])/g, ' $1')}
              />
            ))}
          </div>
        
          <div className="info-file-upload">
            <label className="info-file-label" htmlFor="file-input">
              Upload Images
              <input type="file" onChange={handleFileChange} className="info-file-input" multiple id="file-input" />
            </label>
            {uploadedFiles.length > 0 && (
              <div className="upload-feedback">
                <p>Uploaded {uploadedFiles.length} file(s)</p>
                <div className="uploaded-images">
                  {uploadedFiles.map((file, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt={`Upload preview ${index + 1}`}
                      className="uploaded-image-preview"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          {!loading ? (
            <Button onClick={handleSave} className="info-save-button" variant="contained" color="primary">
              Save Review
            </Button>
          ) : (
            <div className="spinner">
              <CircularProgress />
            </div>
          )}
      </div>
    </div>
  );
};

export default AddReviewPanel;
