import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const FormContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: rgba(0, 0, 0, 0.7);
padding: 30px;
border-radius: 10px;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
max-width: 500px;
margin: 20px;
margin-top: 30%;
`;

const FormTitle = styled.h2`
color: #F5F5DC;
font-size: 2rem;
margin-bottom: 20px;
`;

const StyledTextField = styled(TextField)`
& .MuiInputBase-root {
  background-color: #fff;
}
`;

const StyledButton = styled(Button)`
background-color: #357ABD !important;
color: #fff !important;
&:hover {
  background-color: #28597a !important;
}
margin-top: 20px;
`;

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    message: ''
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid = Object.values(formData).every(field => field.trim() !== '');
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      title: formData.title,
      message: formData.message
    };

    emailjs.send('service_vl1x1wn', 'template_5akvgqa', emailParams, 'y8_gCM4jbyrOKAijB')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          title: '',
          message: ''
        });
        navigate('/');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message. Please try again.');
      });
  };

  return (
    <FormContainer>
      <FormTitle>Contact Us</FormTitle>
      <form onSubmit={handleSubmit}>
        <StyledTextField
          autoFocus
          margin="dense"
          name="firstName"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.firstName}
          onChange={handleChange}
        />
        <StyledTextField
          margin="dense"
          name="lastName"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.lastName}
          onChange={handleChange}
        />
        <StyledTextField
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
        />
        <StyledTextField
          margin="dense"
          name="title"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
        />
        <StyledTextField
          margin="dense"
          name="message"
          label="Message"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
        />
        <StyledButton type="submit" disabled={!isFormValid}>Send</StyledButton>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
