const imagesFrontPage = {
  background: require('../assets/background.jpeg'),
  logo: require('../assets/favicon.ico')
};

const logoGoogleFacebook = {
  GoogleLogo: require('../assets/logo-google.png'),
  FacebookLogo: require('../assets/logo-facebook.png')
};

const logoMarkers = {
  BasicMarker: require('../assets/marker_basic.png'),
  SelectedMarker: require('../assets/marker_selected.png'),
  bluedot: require('../assets/blue_dot.png')
};

export { imagesFrontPage, logoGoogleFacebook, logoMarkers };