import React, { useState, useContext, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Rating, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from '../../../context/UserContext';
import '../../../styles/AddReviewPanelMobile.css'; 

const AddReviewPanel = ({ restaurant, onFileChange, onSave2, onClose, uploadedFiles, setUploadedFiles, loadingImg}) => {
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [photoDate, setPhotoDate] = useState('');
  const [filter, setFilter] = useState('');
  const [infoText, setInfoText] = useState('');
  const [features, setFeatures] = useState({
    tableActivity: false,
    kidsMenu: false,
    indoorPlayingArea: false,
    outdoorPlayingArea: false,
  });
  const { loggedInUser } = useContext(UserContext);
  const [isuploaded, setisUploaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showExtras, setShowExtras] = useState(false);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
    setisUploaded(true);
  };

  const handleFeatureChange = (event) => {
    setFeatures({ ...features, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    if (!isuploaded) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Cache le message après 3 secondes
    } else {
      const reviewData = {
        comment,
        rating,
        photoDate,
        features,
      };
      onSave2(reviewData);
    }
  };

  return (
    <>
      {!loadingImg ? (
        <div className="review-panel-container">
          {/* Close button */}
          <button className="close-button-new" onClick={onClose}>x</button>

          {/* Title */}
          <h2 className="review-panel-title">Add place: {restaurant.name}</h2>

          {/* Upload review button + preview images */}
          <div className="info-file-upload">
            <label className="info-file-label" htmlFor="file-input">
              Upload image(s)
              <input type="file" onChange={handleFileChange} className="info-file-input" multiple id="file-input" />
            </label>
            {uploadedFiles.length > 0 && (
              <div className="upload-feedback">
                <p>Uploaded {uploadedFiles.length} file(s)</p>
                <div className="uploaded-images">
                  {uploadedFiles.map((file, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt={`Upload preview ${index + 1}`}
                      className="uploaded-image-preview"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Review section + Rating*/}
          <div className="review-section">
            <div className="comment-box">
              <TextField
                label="Write your comment..."
                value={comment}
                onChange={e => setComment(e.target.value)}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="rating-section">
              <div className="rating-label">Rate your experience:</div>
              <div className='Rating'>
                <Rating
                  name="rating"
                  value={rating}
                  onChange={(event, newValue) => setRating(newValue)}
                  size="large"
                />
              </div>
            </div>
          </div>

          {/*Save button*/}
          <div>
            <button onClick={handleSave} className="info-save-button">
              SAVE NEW PLACE
            </button>
            {showError && <p style={{ color: 'red' }}>Please upload image(s) to save this place.</p>}
          </div>

          {/*Option+extra*/}
          <div>
            <h3 onClick={() => setShowExtras(!showExtras)}>
              Optional extra information: {showExtras ? '-' : '+'}
            </h3>
            {showExtras && (
              <>
                {/* <TextField
                  label="Date of Photos"
                  type="date"
                  value={photoDate}
                  onChange={e => setPhotoDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                /> */}
              <div className="features-section">
              <h3>Features:</h3>
              <div className="features-section-checkbox">
                {["tableActivity", "kidsMenu", "indoorPlayingArea", "outdoorPlayingArea"].map(feature => (
                  <FormControlLabel
                    key={feature}
                    control={<Checkbox checked={features[feature]} onChange={handleFeatureChange} name={feature} />}
                    label={feature.charAt(0).toUpperCase() + feature.slice(1).replace(/([A-Z])/g, ' $1')}
                  />
                ))}
              </div>
            </div>


              </>
            )}
          </div>
          
        </div>
      ) : null}
      {loadingImg && (
        <div className="modal-overlay">
          <div className="modal-content">
            <CircularProgress />
            <p>Adding the review in progress...</p>
          </div>
      </div>
      
      )} 
    </>
  );
};

export default AddReviewPanel;