// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import useDeviceDetect from './hooks/useDeviceDetect';


//Welcome Page (front page)
import WelcomeComponentDesktop from './components/WelcomePage/WelcomeComponentDesktop';
import WelcomeComponentMobile from './components/WelcomePage/WelcomeComponentMobile';
//Contact Page
import ContactFormPageDesktop from './components/ContactPage/ContactFormPageDesktop'; 
import ContactFormPageMobile from './components/ContactPage/ContactFormPageMobile'; 
//Map Page
import MapComponentDesktop from './components/MapPage/Desktop/MapComponentDesktop';
import MapComponentMobile from './components/MapPage/Mobile/MapComponentMobile';
//AddNewPlace
//import AddNewPlaceDesktop from './components/AddNewPlace/Desktop/AddNewPlaceDesktop';
import AddNewPlaceMobile from './components/AddNewPlace/Mobile/AddNewPlaceMobile';
import AddNewPlaceOwner from './components/AddNewPlace/Mobile/AddNewPlaceOwner';
import InfoWindowContent from './components/AddNewPlace/Mobile/InfoWindowContent';


function App() {
  const { isMobile } = useDeviceDetect(); 
  const [isAddNewPlaceOpen, setIsAddNewPlaceOpen] = useState(true); 


  return (
    <Router>
      <UserProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={isMobile ? <WelcomeComponentMobile /> : <WelcomeComponentDesktop />} />
            <Route path="/contact" element={isMobile ? <ContactFormPageMobile /> : <ContactFormPageDesktop />} />
            <Route path="/map" element={isMobile ? <MapComponentMobile /> : <MapComponentDesktop />} />
            <Route path="/add-new-place" element={isMobile ? <AddNewPlaceMobile /> : <AddNewPlaceMobile />} />
            <Route path="/create-restaurant/:restaurantId/:token" element={<AddNewPlaceMobile isAddNewPlaceOpen={isAddNewPlaceOpen} setIsAddNewPlaceOpen={setIsAddNewPlaceOpen}/>} />
          </Routes>
        </div>
      </UserProvider>
    </Router>
  );
}



export default App;


