import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
`;

export const AppBarStyle = {
  position: 'absolute',
  width: '100%',
  zIndex: 1 
};

export const Container = styled.div`
  position: relative; // For absolute positioning of children
  height: 100vh;
  width: 100vw;
`;

export const SidebarStyle = {
  position: 'fixed',
  top: 75,
  left: 0,
  zIndex: 5,
  width: '400px',
  height: '52.5%',
  overflowY: 'auto',
  backgroundColor: '#fff',
  transition: 'left 0.3s ease-in-out',
  boxShadow: '2px 0 10px rgba(0,0,0,0.3)'
};

export const SearchBarStyle = styled.div`
  position: absolute;
  top: 6%; // Ajustez cette valeur selon la hauteur de votre NavBar
  left: 1%;
  width: 103%; // Ajustez selon la taille désirée
  z-index: 3; // Assurez-vous que la barre de recherche reste au-dessus de la carte
  box-shadow: 0 0px 0px rgba(0,0,0,0.3);
`;


export const InfoContentEditMap = (isSelectedPOI) => ({
    position: 'fixed',
    top: '15%',
    left: isSelectedPOI ? '1%' : '-30%',
    zIndex: 5,
    width: '30%',
    height: '90%',
    transition: 'left 0.3s ease-in-out',
    backgroundColor: '#fff',  
    borderRadius: '12px',      
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', 
    padding: 0,
    maxWidth: '100%',
    maxHeight: '60%',
    margin: '0 auto',
  });


  export const RestaurantWindowMainMap = (selectedRestaurant) => ({
    position: 'fixed',
    top: '15%',
    left: selectedRestaurant ? '1%' : '-30%',
    zIndex: 5,
    width: '30%',
    height: '90%',
    transition: 'left 0.3s ease-in-out',
    backgroundColor: '#fff',  
    borderRadius: '12px',      
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', 
    padding: 0,
    maxWidth: '100%',
    maxHeight: '80%',
    margin: '0 auto',
  });
  

  export const mapContainerStyle = {
    height: 'calc(100vh)', 
    width: '100%',
  };
  
  export const messageStyle = {
    animation: 'fadeInOut 2s ease-in-out', 
    opacity: 1,  
    color: '#333',  
    textAlign: 'center',
    fontSize: '14px',  
    padding: '8px 20px',  
    position: 'absolute',
    top: '10%',  
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',  
    backgroundColor: '#f8f8f8',  
    boxShadow: '0 2px 4px rgba(0,0,0,0.06)',  
    borderRadius: '4px',  
    zIndex: 1000
  };

  export const WidgetContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  overflow: hidden;
  display: block; // Assume always displayed for simplicity
`;


export const WidgetContent = styled.div`
height: 50px;
`;

export const DragHandle = styled.div`
position: relative;
  height: 60px;
  cursor: grab; // Style de curseur pour indiquer qu'il est déplaçable
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index:250;
`;

export const DragIcon = styled.div`
  position: relative;
  width: 30px;
  height: 5px;
  left: 47.5vw;
  top: 10px;
  background-color: #999;
  border-radius: 3px;
`;

export const TextDrag = styled.div`
  position: relative;
  top: 0px;
  left: 10px;
  font-size: 1.5em;      // Taille de police typique pour un h2
  font-weight: bold;     // Rend le texte en gras
  margin-top: 20px;      // Ajoute un espace en haut
  margin-bottom: 20px;   // Ajoute un espace en bas
  font-family: Arial, sans-serif; // Police généralement utilisée pour les en-têtes
`;

export const CloseIcon = styled.div`
position: absolute; 
  left: 30px;  
  top: -30px; 
  transform: translateX(-50%); 
  width: 30px; 
  height: 50px;  
  cursor: pointer; 
  display: flex;  
  align-items: center; 
  justify-content: center;  
  z-index: 9999;

  &:before, &:after {
    content: '';  /* Contenu vide nécessaire pour le pseudo-élément */
    position: absolute;  /* Positionnement absolu pour précision */
    bottom: 0;  /* Positionner les barres à la base du div */
    width: 3px;  /* Largeur des barres de la flèche */
    height: 15px;  /* Hauteur des barres de la flèche */
    background-color: #999;  /* Couleur des barres */
    transform-origin: bottom;  /* Pivot de rotation au bas des barres */
    border-radius: 3px;
  }

  &:before {
    transform: rotate(45deg);  /* Inclinaison vers la droite pour la barre gauche */
    left: 6px;  /* Ajustement de la position horizontale de la barre gauche */
  }

  &:after {
    transform: rotate(-45deg);  /* Inclinaison vers la gauche pour la barre droite */
    right: 20px;  /* Ajustement de la position horizontale de la barre droite */
  }
`;




export const WidgetContentInfo = styled.div`
  height: calc(100% - 20px);
  overflow-y: auto;
  padding: 20px;
`;

export const WidgetContainerInfo = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  overflow: hidden;
  display: block;
`;



export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  color: #333;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: #f44336;
  }
`;