import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import styled from 'styled-components';

const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px; /* Taille maximale de la barre de recherche */
  margin: 0 auto; /* Centrer la barre de recherche horizontalement */
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px; /* Espace intérieur pour rendre la saisie confortable */
  font-size: 1rem;
  border: 1px solid #ccc; /* Bordure subtile */
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:focus {
    border-color: #357ABD; /* Couleur de bordure lorsque l'input est en focus */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Augmente l'ombre pour attirer l'attention */
  }
`;

function SearchBarAll({ map, onPlaceSelected, setTemporaryMarker }) {
  const autocompleteRef = useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current && autocompleteRef.current.getPlace().geometry) {
      const place = autocompleteRef.current.getPlace();
      map.panTo(place.geometry.location);
      
      // Ajustement du niveau de zoom en fonction du type de lieu
      let zoomLevel = 12; 
      if (place.types.includes('restaurant')) {
        zoomLevel = 18; 
      }
      map.setZoom(zoomLevel);

      // Mise à jour du marqueur temporaire et notification du lieu sélectionné
      onPlaceSelected(place);
      setTemporaryMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    }
  };

  return (
    <SearchBarContainer>
      <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
        <SearchInput
          type="text"
          placeholder="Search for address"
        />
      </Autocomplete>
    </SearchBarContainer>
  );
}

export default SearchBarAll;
