import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import '../../../styles/AddNewPlaceOwner.css'; 
import { IN_DEV, localhost_server, path_server } from '../../api.js';
import { TextField, Checkbox, FormControlLabel, Button,IconButton, UploadIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AddNewPlaceOwner = ({ handleClose, onSave2 , onClose, onFileChange , setUploadedFiles ,loadingImg, uploadedFiles}) => {  
  const { restaurantId, token } = useParams();
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
 
  const { loggedInUser, setLoggedInUser } = useContext(UserContext);
  const [panelActive, setPanelActive] = useState(false);
  const [isexplanation, setexplanation] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!!loggedInUser);
  const [restaurant, setRestaurant] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showExtras, setShowExtras] = useState(false);
  const [isuploaded, setisUploaded] = useState(false);
  const [photoDate, setPhotoDate] = useState('');

  const [features, setFeatures] = useState({
    tableActivity: false,
    kidsMenu: false,
    indoorPlayingArea: false,
    outdoorPlayingArea: false,
  });

  const [formData, setFormData] = useState({
    signInEmail: '',
    signInPassword: '',
    firstName: '',
    lastName: '',
    registerEmail: '',
    registerPassword: '',
    confirmPassword: ''
  });

  // Handle feature checkbox changes
  const handleFeatureChange = (event) => {
    setFeatures({ ...features, [event.target.name]: event.target.checked });
  };

  // Fetch restaurant name and validate token on mount
  useEffect(() => {
    if (loggedInUser) {
      setIsAuthenticated(true);
    }
    
    const fetchRestaurantName = async () => {
      const response = await fetch(`${IN_DEV ? localhost_server : path_server}/api/get-restaurant-by-token/${restaurantId}/${token}`);
      if (response.ok) {
        const data = await response.json();
        setRestaurant(data.restaurant);
        setIsTokenValid(true);
       
        
      } else {
        console.error('Failed to fetch restaurant data');
        setIsTokenValid(false);
      }
    };

    fetchRestaurantName();
  }, [loggedInUser, restaurantId, token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleCloseSignIn = () => {
    setPanelActive(false);
  };

  const handleCloseExplanation = () => {
    setexplanation(false);
  };
  

  const baseUrl = IN_DEV ? localhost_server : path_server;

  // Handle sign-in logic
  const signIn = (event) => {
    event.preventDefault();
    const { signInEmail, signInPassword } = formData;
    fetch(`${baseUrl}/api/signin-owner/${restaurantId}/${token}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email: signInEmail, password: signInPassword })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setLoggedInUser(data.user);
          localStorage.setItem('loggedInUser', JSON.stringify(data.user));
          setIsAuthenticated(true);
          handleClose();
        } else {
          alert('Error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Handle registration logic
  const register = (event) => {
    event.preventDefault();
    const { firstName, lastName, registerEmail, registerPassword, confirmPassword } = formData;
    if (registerPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    fetch(`${baseUrl}/api/register-owner/${restaurantId}/${token}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName, lastName, email: registerEmail, password: registerPassword })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setLoggedInUser(data.user);
          localStorage.setItem('loggedInUser', JSON.stringify(data.user));
          alert('Registered successfully!');
          setIsAuthenticated(true);
          handleClose();
        } else {
          alert('Error: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Handle file upload logic
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
    setisUploaded(true);
  };

  // Handle save logic with error handling for missing uploads
  const handleSave = () => {
    if (!isuploaded) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Hide error message after 3 seconds
    } else {
      const reviewData = {
        comment,
        
        photoDate,
        features,
        restaurantId
      };
      onSave2(reviewData);
    }
  };

  return (
<>
  {isTokenValid ? (
    isAuthenticated ? (
      <>
        <div className="welcome-message-container">
          <h2 className="add-new-place-owner-welcome">
            Welcome, {loggedInUser.firstName} {loggedInUser.lastName}!
          </h2>
        </div>

        {!loadingImg ? (
          <div className="review-panel-container-owner">
            <h2 className="review-panel-title-owner">This is Your place:</h2>
            <h2 className="review-panel-restaurant-owner">{restaurant.name}</h2>

            <div className="review-section-owner">
              <div className="comment-box-owner">
                <TextField
                  label= " Write info about your restaurant..."
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  className="text-field-owner"
                />
              </div>
            </div>

            <div className="info-file-upload-owner">
              <label className="info-file-label-owner" htmlFor="file-input">
                <IconButton component="span" className="upload-icon-button-owner">
                  <CloudUploadIcon fontSize="large" />
                </IconButton>
                <span>Upload image(s) of your establishment</span>
                <input type="file" onChange={handleFileChange} className="info-file-input-owner" multiple id="file-input" />
              </label>
              {uploadedFiles.length > 0 && (
                <div className="upload-feedback-owner">
                  <p>Uploaded {uploadedFiles.length} file(s)</p>
                  <div className="uploaded-images-owner">
                    {uploadedFiles.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Upload preview ${index + 1}`}
                        className="uploaded-image-preview-owner"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>


            
            <div>
              <button onClick={handleSave} className="info-save-button-owner">
                SAVE YOUR PLACE
              </button>
              {showError && <p className="error-message-owner">Please upload image(s) to save this place.</p>}
            </div>
            <div>
              <h3 onClick={() => setShowExtras(!showExtras)} className="extras-toggle-owner">
                Optional extra information: {showExtras ? '-' : '+'}
              </h3>
              {showExtras && (
                <div className="features-section-owner">
                  <h3>Features:</h3>
                  <div className="features-section-checkbox-owner">
                    {["tableActivity", "kidsMenu", "indoorPlayingArea", "outdoorPlayingArea"].map(feature => (
                      <FormControlLabel
                        key={feature}
                        control={<Checkbox checked={features[feature]} onChange={handleFeatureChange} name={feature} />}
                        label={feature.charAt(0).toUpperCase() + feature.slice(1).replace(/([A-Z])/g, ' $1')}
                        className="checkbox-owner"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {loadingImg && (
          <div className="modal-overlay">
            <div className="modal-content">
              <CircularProgress />
              <p>Adding the review in progress...</p>
            </div>
          </div>
        )}
      </>
    ) : (
      <div className="add-new-place-owner-container">
        <div className={`add-new-place-owner-form-container ${panelActive ? 'right-panel-active' : ''}`} id="container">
          {panelActive ? (
            <div className="register-container">
                        {isexplanation && (
            <div className="explanation-container-owner">
              <button className="close-button-owner" onClick={handleCloseExplanation}>×</button>
              <h2 className="explanation-title-owner">What this page is about:</h2>
              <p className="explanation-text-owner">
              You have been provided with a unique link, confirming your ownership of this restaurant. This page allows you to add your establishment to the Diningwithkids website. By doing so, you will not only enhance your visibility but also invite families to discover your venue, share their experiences, and attract more families with children—aligning with our mission to create family-friendly dining experiences.              </p>
            </div>
          )}
              <form onSubmit={register}>
                <h1>Create Account</h1>
                <h2>For {restaurant.name}</h2>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <input
                  type="email"
                  id="registerEmail"
                  placeholder="Email"
                  value={formData.registerEmail}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <input
                  type="password"
                  id="registerPassword"
                  placeholder="Password"
                  value={formData.registerPassword}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <button type="submit" className="add-new-place-owner-button">Register</button>
                <button type="button" className="add-new-place-owner-button" onClick={handleCloseSignIn}>
                  Already have an account? Sign In
                </button>
              </form>
            </div>
          ) : (
            <div className="sign-in-container">
          {isexplanation && (
            <div className="explanation-container-owner">
              <button className="close-button-owner" onClick={handleCloseExplanation}>×</button>
              <h2 className="explanation-title-owner">What this page is about:</h2>
              <p className="explanation-text-owner">
                You have received a unique link which signifies that this restaurant is yours. This page will allow you to add your restaurant to the Diningwithkids website. By doing so, you will enable families to discover your place, leave comments, and attract more families with children, which is the main goal of our website.
              </p>
            </div>
          )}
              <form onSubmit={signIn}>
                <h1>Sign in as an owner of:</h1>
                <h1>{restaurant.name}</h1>
                <input
                  type="email"
                  id="signInEmail"
                  placeholder="Email"
                  value={formData.signInEmail}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <input
                  type="password"
                  id="signInPassword"
                  placeholder="Password"
                  value={formData.signInPassword}
                  onChange={handleChange}
                  required
                  className="add-new-place-owner-input"
                />
                <button type="submit" className="add-new-place-owner-button">Sign In</button>
                <button type="button" className="add-new-place-owner-button" onClick={() => setPanelActive(true)}>
                  Need an account? Register
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    )
  ) : (
    <div className="error-message-container">
      <h2 className="error-message">Token and restaurant ID do not match. Please contact support for more information.</h2>
    </div>
  )}
</>

  );
};

export default AddNewPlaceOwner;