import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Typography, Grid, Button} from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { renderToString } from 'react-dom/server';



//Modal
import RestaurantInfoWindow from './RestaurantInfoWindow';
import SearchBarAll from './SearchBarAll'; 
import AuthApp from './AuthApp';

//Other
import { UserContext } from '../../../context/UserContext'; 
import { IN_DEV, localhost_server, path_server, fetchRestaurants } from '../../api.js';
import { imagesFrontPage, logoMarkers } from '../../../config/path';
import { GlobalStyle, Container, AppBarStyle, SearchBarStyle, WidgetContainer, WidgetContent, DragHandle, DragIcon, TextDrag, CloseIcon} from './styleMobile.js';
import  {Navbar,  Logo, LogoImage, LogoText, ActionButton, LoginButton, LogoutButton } from '../../../styles/NavBarStyleMobile';


const mainMapId = "32f8a5e3c0c9082";    
const GoogleMap_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const center = {
  lat: 59.911491,
  lng: 10.757933
};


const MapComponent = () => {
  const { loggedInUser, setLoggedInUser } = useContext(UserContext); 
  const [userLocation, setUserLocation] = useState(null);
  const [map, setMap] = useState(null);
  const [showMainMap, setShowMainMap] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [file, setFile] = useState(null);
  const [showAuth, setShowAuth] = useState(false);
  const [addnewplace, setAddNewPlace] = useState(false);
  const [leftPaneWidth, setLeftPaneWidth] = useState(window.innerWidth * 0.35);  
  const [zoom, setZoom] = useState(15); 
  const [temporaryMarker, setTemporaryMarker] = useState(null); 
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dragHandleRef = useRef(null);
  const widgetRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [userLocationEnabled, setUserLocationEnabled] = useState(false);
  const [userMarker, setUserMarker] = useState(null);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [normalIcon, setNormalIcon] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [locationIcon, setLocationIcon] = useState(null);



  
  const widgetContentRef = useRef(null);
  const [childModelRestaurantInfo, setchildModelRestaurantInfo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const startY = useRef(0);
  const [defaultHeight, setDefaultHeight] = useState(500);
  const currentHeight = useRef(defaultHeight);
  const [loadingImg, setLoadingImg] = useState(false);
  

  
  

  const handleNavigate = (path) => {
    navigate(path);
  };

  const disableMapInteraction = () => {
    if (map) {
      map.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true
      });
    }
  };
  
  // Fonction pour réactiver les interactions avec la carte
  const enableMapInteraction = () => {
    if (map) {
      map.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false
      });
    }
  };


  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
  
    if (scrollTop === 0) {
      console.log("Atteint le haut");
      // Action quand on atteint le haut
    } else if (scrollTop + clientHeight >= scrollHeight) {
      console.log("Atteint le bas");
      // Action quand on atteint le bas
    }
  };
  

  // Supposons que `modalContentRef` est une référence à l'élément DOM `.modal-content`
  useEffect(() => {
    const modalContent = widgetContentRef.current;
    if (modalContent) {
      modalContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


  

  const handleCloseRestaurantWindow = () => {
    setDefaultHeight(500);
    setSelectedRestaurant(null);
    enableMapInteraction();
    setchildModelRestaurantInfo(false);
    setIsModalOpen(false);
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); 
  };

  const handleSaveInfo = (reviewData) => {
    setLoadingImg(true);

    const userId = loggedInUser ? loggedInUser.id : null;
    const restaurantId = selectedRestaurant._id.$oid;
    const formData = new FormData();
  
    // Append review information
    formData.append('reviewText', reviewData.comment);
    formData.append('rating', reviewData.rating);
    formData.append('photoDate', reviewData.photoDate);
    formData.append('features', JSON.stringify(reviewData.features));
    formData.append('userId', userId);
    formData.append('restaurantId', restaurantId);
  
    // Append uploaded files
    uploadedFiles.forEach(file => {
      formData.append('image', file);
    });
  
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/add-new-review`, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setMessage('Information saved successfully!');
          setIsError(false);
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage('Error saving information. Please try again.');
        setIsError(true);
      })
      .finally(() => {
        setInfoText('');
        setUploadedFiles([]);
        setLoadingImg(false);
        enableMapInteraction(); 
        setSelectedRestaurant(null);
        alert('New place added succesfully!');
      });
  };

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     const widgetElement = widgetRef.current;
  //     if (widgetElement && !widgetElement.contains(event.target) && !isModalOpen) {
  //       handleCloseRestaurantWindow();
  //     }
  //   };
  //   document.addEventListener('touchstart', handleOutsideClick);
  
  //   return () => {
  //     document.removeEventListener('touchstart', handleOutsideClick);
  //   };
  // }, [isModalOpen]);

  const scrollToTop = () => {
    const widgetContent = widgetContentRef.current;
    console.log("1")
    if (widgetContent) {
      console.log(widgetContentRef.current?.scrollTop)
      widgetContent.scrollBottom = 0;
    }
  };

  useEffect(() => {
    const dragElement = dragHandleRef.current;
    const handleMove = (moveEvent) => {
      if (moveEvent.touches && moveEvent.touches.length > 0 && dragElement) {
        moveEvent.preventDefault();
        const currentY = moveEvent.touches[0].clientY;
        const deltaY = currentY - startY.current;
        const newHeight = Math.max(50, currentHeight.current - deltaY); 
        widgetRef.current.style.height = `${newHeight}px`;
        if (parseFloat(widgetRef.current.style.height) < currentHeight.current * 0.25) {
          handleCloseRestaurantWindow();
          enableMapInteraction();
        }
      }
    };

    const handleEnd = (moveEvent) => {
      moveEvent.preventDefault();
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
      currentHeight.current = parseFloat(widgetRef.current.style.height); 
    };

    const handleStart = (e) => {
      e.preventDefault();
      if (e.touches && e.touches.length > 0) {
        startY.current = e.touches[0].clientY;
        currentHeight.current = parseFloat(widgetRef.current.style.height); 
        document.addEventListener('touchmove', handleMove, { passive: false });
        document.addEventListener('touchend', handleEnd, { passive: false });
      }
    };


    if (dragElement) {
      dragElement.addEventListener('touchstart', handleStart, { passive: false });
    }

    return () => {
      if (dragElement) {
        dragElement.removeEventListener('touchstart', handleStart);
      }
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [handleCloseRestaurantWindow, dragHandleRef, widgetRef]);

  useEffect(() => {
  }, [currentHeight.current]);



  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userPosition);
          setLocationIcon({
            url: logoMarkers.bluedot,
            scaledSize: new window.google.maps.Size(10, 10),
          });
        },
        (error) => console.error("Error while getting the localisation of the user.", error)
      );
    }
  }, []);



  const handleAuthButtonClick = () => {
    if(!loggedInUser){
      setShowAuth((prevShowAuth) => !prevShowAuth);
      setAddNewPlace(true);
    } else {
      handleNavigate('/add-new-place')
    }
  };

  const handleMarkerClick = (event, restaurant) => {
    event.stop();

    if (selectedRestaurant && selectedRestaurant._id === restaurant._id) {
    } else {
      setLoading(true);
      setSelectedRestaurant(restaurant); 
    }
};



const iconUserLocationUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
  renderToString(<LocationOnIcon style={{ fontSize: 64, color: '#FF5722' }} />)
)}`;



const handleGeolocationButtonClick = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserLocation(userPosition);

        if (map) {
          map.panTo(userPosition);
          map.setZoom(15);
        }
      },
      () => {
        console.error("Error while getting the user's location.");
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};




  
  const onMapLoad = useCallback((map) => {
    setMap(map);
    setMapsLoaded(true);
    map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });
    map.addListener('click', (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({
          placeId: event.placeId,
          fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours', 'website',  'business_status', 'rating', 'user_ratings_total']
        }, (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedPOI({
              name: result.name,
              address: result.formatted_address,
              place_id: result.place_id,
              phone: result.formatted_phone_number,
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
              googleMapsUrl: result.url,
              price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
              opening_hours: result.opening_hours ? result.opening_hours.weekday_text : [],
              website: result.website ? result.website : "No website available",
              business_status: result.business_status ? result.business_status : "No business status available",
              rating: result.rating ? result.rating : "No ratings available",
              user_ratings_total: result.user_ratings_total ? result.user_ratings_total : "No user ratings total available",
            });
            setMessage('');
            setShowMessage(false);
            setTemporaryMarker(null); 
          }
        });
      }
    });
  }, []);

  const handlePlaceSelected = (place) => {
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId: place.place_id,
      fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours']
    }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPOI({
          name: result.name,
          address: result.formatted_address,
          place_id: result.place_id,
          phone: result.formatted_phone_number,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
          googleMapsUrl: result.url,
          price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
          opening_hours: result.opening_hours ? result.opening_hours.weekday_text : []
        });
        map.panTo(result.geometry.location);
        map.setZoom(15);
        setMessage('');
        setShowMessage(false);
      }
    });
  };

  const handleDeleteRestaurant = (restaurantId) => {
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/restaurant/${encodeURIComponent(restaurantId)}`, {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        setRestaurants((prevRestaurants) =>
          prevRestaurants.filter((restaurant) => restaurant._id.$oid !== restaurantId)
        );
        setSelectedRestaurant(null);
      } else {
        console.error('Failed to delete restaurant', data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleCloseButtonRestaurantWindow = (event) => {
    event.stopPropagation();
    enableMapInteraction(); 
    setSelectedRestaurant(null);
  };

  useEffect(() => {
    const handleResizeWindow = () => {
      setLeftPaneWidth(window.innerWidth * 0.35);
    };

    window.addEventListener('resize', handleResizeWindow);
    return () => window.removeEventListener('resize', handleResizeWindow);
  }, []);

  
  useEffect(() => {
    let timer;
    if (message) {
      setShowMessage(true);
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); 
    }
    return () => clearTimeout(timer); 
  }, [message]);

  useEffect(() => {
    if (showMainMap) {
      fetchRestaurants().then(setRestaurants).catch(error => {
        console.error('Error:', error);
        setRestaurants([]);
      });
    }
  }, [showMainMap]);

  const login = () => {
    setShowAuth((prevShowAuth) => !prevShowAuth);
  };

  const Logout= () => {
    {
      console.log("User logged out");
      setLoggedInUser(null);
      localStorage.removeItem('loggedInUser'); 
    }
  };


  useEffect(() => {
    async function fetchUserData() {
      try {
        const url = IN_DEV ? localhost_server : path_server;
        const response = await fetch(`${url}/api/check-login-status`, { credentials: 'include' });
        const data = await response.json();
        if (data.loggedIn) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, []);


  useEffect(() => {
    if (mapsLoaded) {
      setNormalIcon({
        url: logoMarkers.BasicMarker,
        scaledSize: new window.google.maps.Size(60, 60),
      });
    
      setSelectedIcon({
        url: logoMarkers.SelectedMarker,
        scaledSize: new window.google.maps.Size(55, 55),
      });
    }
  }, [mapsLoaded]);
  
  
  
  return (
    <>
      <GlobalStyle />
      <Container>
      <AppBar position="static" color="primary" style={AppBarStyle}>
      <Navbar>
            <Logo onClick={() => handleNavigate('/')}>
              <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
              <LogoText>
                {loggedInUser ? (
                  <>
                    Welcome, {loggedInUser.firstName}!
                  </>
                ) : (
                  <>
                    Dining with Kids
                  </>
                )}
              </LogoText>
            </Logo>
            
            <ActionButton onClick={handleAuthButtonClick}>
              Add new place
            </ActionButton>

            {!loggedInUser ? (
              <LoginButton onClick={login}>
                Sign In
              </LoginButton>
            ) : (
              <LogoutButton onClick={Logout}>
                Log Out
              </LogoutButton>
            )}
          </Navbar>
      </AppBar>
      
        <Grid container direction={'row'}>
          <Grid item xs={12} md={12} style={{ width: '100vh', height: '100%' }}>

        <Button
              variant="contained"
              color="grey"
              onClick={handleGeolocationButtonClick}
              style={{
                position: 'absolute',
                top: '15%',
                right: '10px',
                zIndex: '10',
                minWidth: 'auto', // Pour que le bouton prenne la taille de l'icône
                padding: '10px',
              }}
            >
              <MyLocationIcon />
            </Button>
            
            
            {/* {WINDOW TO SEE THE COMMENT AND PICTURES POSTED} */}
            {selectedRestaurant && (
              <WidgetContainer ref={widgetRef} style={{ height: `${currentHeight.current}px`, maxHeight: '85vh' }}>
                <DragHandle ref={dragHandleRef} >
                  <DragIcon />
                  <CloseIcon
                    onTouchStart={(e) => {
                      e.stopPropagation();
                      handleCloseButtonRestaurantWindow(e);
                    }}
                  />                  
                </DragHandle>
                <WidgetContent 
                  ref={widgetContentRef} >
                  <RestaurantInfoWindow 
                    restaurant={selectedRestaurant}
                    onFileChange={handleFileChange}
                    onClose={() => {
                      enableMapInteraction(); 
                      setSelectedRestaurant(null);
                    }} 
                    onDelete={handleDeleteRestaurant}
                    onSave={handleSaveInfo}
                    loading={loading}
                    setLoading={setLoading}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    isModalOpen = {isModalOpen}
                    setIsModalOpen = {setIsModalOpen}
                    loadingImg={loadingImg}
                  />
                </WidgetContent>
              </WidgetContainer>
            )} 

            <LoadScript googleMapsApiKey={GoogleMap_API_KEY} libraries={['places']}>
            <SearchBarStyle>
              {map && <SearchBarAll map={map} onPlaceSelected={handlePlaceSelected} setTemporaryMarker={setTemporaryMarker} />}
            </SearchBarStyle>

              <GoogleMap
                key={'custom-map'}
                mapContainerStyle={{ height: '100vh', width: '100%' }}
                center={userLocation? userLocation: center}
                zoom={zoom}
                onLoad={map => onMapLoad(map)}
                options={{
                  mapId: mainMapId,
                  mapTypeControl: false, 
                  fullscreenControl: false,
                  streetViewControl: false, 
                  zoomControl: false, 
                  gestureHandling: 'greedy',     
                }}
                
              >
                {mapsLoaded ? (
                  restaurants.filter(restaurant => !restaurant.IN_DEV).map(restaurant => (
                    <Marker
                      key={restaurant._id || restaurant.place_id}
                      position={{ lat: restaurant.location.lat, lng: restaurant.location.lng }}
                      icon={selectedRestaurant && restaurant._id === selectedRestaurant._id ? selectedIcon : normalIcon}
                      label={zoom >= 14 ? {
                        text: restaurant.name,
                        color: "green",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        border: "1px solid #0056b3"
                      } : null}
                      onClick={(e) => handleMarkerClick(e, restaurant)}
                    />
                  ))
                ) : null}

                {mapsLoaded && path_server=="https://dev.diningwithkids.com" ? (
                  restaurants.filter(restaurant => restaurant.IN_DEV).map(restaurant => (
                    <Marker
                      key={restaurant._id || restaurant.place_id}
                      position={{ lat: restaurant.location.lat, lng: restaurant.location.lng }}
                      icon={selectedRestaurant && restaurant._id === selectedRestaurant._id ? selectedIcon : normalIcon}
                      label={zoom >= 14 ? {
                        text: "ONLY FOR DEV",
                        color: "red",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        border: "1px solid #0056b3"
                      } : null}
                      onClick={(e) => handleMarkerClick(e, restaurant)}
                    />
                  ))
                ) : null}


            
{userLocation && zoom >= 14 &&  (
          <Marker
            position={userLocation}
            icon={locationIcon}
          />
        )}


              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
      </Container>
      {showAuth && <AuthApp handleClose={handleAuthButtonClick} addnewPlace={addnewplace} setAddNewPlace={setAddNewPlace}/>}
    </>
  );
}

export default MapComponent;