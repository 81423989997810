import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';

function SearchBarAll({ map, onPlaceSelected, howItWorks, setStep, totRefresh }) {
  const autocompleteRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [highlightCircle, setHighlightCircle] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Détection de mobile (exemple: largeur <= 768px)
    };

    // Vérifier au montage du composant
    checkMobile();

    // Écouter les changements de redimensionnement de la fenêtre
    window.addEventListener('resize', checkMobile);

    // Nettoyage de l'écouteur à la suppression du composant
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (totRefresh) {
      setInputValue(''); 
      if(selectedMarker){
        selectedMarker.setMap(null);
        setSelectedMarker(null);
      }
      if (highlightCircle) {
        highlightCircle.setMap(null); 
        setHighlightCircle(null);
      }
    }
  }, [totRefresh]);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current && autocompleteRef.current.getPlace().geometry) {
      const place = autocompleteRef.current.getPlace();
      setInputValue(`${place.name}, ${place.formatted_address}`);
  
      map.panTo(place.geometry.location);
      map.setZoom(18);
  
      if (selectedMarker) {
        selectedMarker.setMap(null);
      }
  
      const marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: map,
        scaledSize: new window.google.maps.Size(40, 40)
      });
  
      marker.addListener('click', () => {
        onPlaceSelected(place); 
        if (howItWorks) {
          setStep(3);
        }
      });
  
      setSelectedMarker(marker);
  
      if (howItWorks) {
        setStep(2);
        if (highlightCircle) {
          highlightCircle.setMap(null);
        }
        const circle = new window.google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 5,
          fillOpacity: 0,
          map,
          center: place.geometry.location,
          radius: 30, 
        });
        setHighlightCircle(circle);
      }
    }
  };
  

  return (
    <div>
      <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Search for address..."
          style={isMobile ? {} : { 
            fontSize: '16px',
            width: '30%',
            padding: '12px 20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            outline: 'none',
            transition: 'all 0.3s ease',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            top: '100%',
            marginTop:'2%',
          }} 
          value={inputValue}
          onChange={handleInputChange}
        />
      </Autocomplete>
    </div>
  );
}

export default SearchBarAll;
