import React, { useState, useContext } from 'react';
import '../../../styles/InfoWindowContentMobile.css'; 
import { UserContext } from '../../../context/UserContext';
import AddReviewPanel from './AddReviewPanel'; 
import AddNewPlaceOwner from './AddNewPlaceOwner';

const InfoWindowContent = ({ restaurant, onFileChange, onSave, onClose, uploadedFiles, setUploadedFiles, loadingImg, setStep, howItWorks, setHowItWorks, totRefresh, isAddNewPlaceOpen }) => {
  const [photoIndex, setPhotoIndex] = useState(0);  
  const { loggedInUser } = useContext(UserContext);
  const [isReviewPanelOpen, setIsReviewPanelOpen] = useState(false);

  const [isGeneratingToken, setIsGeneratingToken] = useState(false);

  const nextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % restaurant.photos.length);
  };

  const previousPhoto = () => {
    setPhotoIndex(photoIndex === 0 ? restaurant.photos.length - 1 : photoIndex - 1);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
  };

  const handleAddReviewButton = () => {
    setIsReviewPanelOpen(true);
  
  }



  const handleCloseButton = () => {
    setIsReviewPanelOpen(false); 

    setUploadedFiles([]);
    onClose();
    if (howItWorks) {
      setStep(0);
      setHowItWorks(false);
      totRefresh();
    }
  }

  const generateToken = async () => {
    setIsGeneratingToken(true);
    const formData = new FormData();

    // Append general restaurant information
    formData.append('name', restaurant.name || '');
    formData.append('location', JSON.stringify({ 
        lat: restaurant.lat, 
        lng: restaurant.lng, 
        full_address: restaurant.address 
    }));
    formData.append('generalInfos', JSON.stringify({
        phone: restaurant.phone || '',
        googleMapsUrl: restaurant.googleMapsUrl || '',
        price_range: restaurant.price_range || '',
        opening_hours: restaurant.opening_hours || [],
        website: restaurant.website || '',
        business_status: restaurant.business_status || '',
        rating_google_maps: restaurant.rating || 0,
        user_ratings_google_maps_total: restaurant.user_ratings_total || 0
    }));

    try {
        const response = await fetch('/api/upload-restaurant-save-owner-token', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const data = await response.json();

            if (data.status === "exists") {
              const uniqueLink = `${window.location.origin}/create-restaurant/${data.restaurant_id}/${data.token}`;
              console.log('Unique link for the restaurant owner:', uniqueLink);
              alert(`Token already exists for this restaurant, this is the unique link:${uniqueLink}`);
            } else if (data.status === "success") {
                const uniqueLink = `${window.location.origin}/create-restaurant/${data.restaurant_id}/${data.token}`;
                console.log('Unique link for the restaurant owner:', uniqueLink);
                alert(`Generated link : ${uniqueLink}`);
            } else {
                console.error('Erreur lors de la sauvegarde du token');
            }
        } else {
            console.error('Erreur lors de la sauvegarde du token');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    } finally {
        setIsGeneratingToken(false);
    }
  }
 

  return (
    <div className="main-content-window">
      {!howItWorks && (
        <button className="close-button" onClick={onClose}>x</button>
      )}
      
      <h2 className="info-name">{restaurant.name}</h2>
      {restaurant.photos && restaurant.photos.length > 0 && (
        <div className="photo-gallery">
          <button onClick={previousPhoto} className="gallery-button gallery-button-left">{"<"}</button>
          <img 
            src={restaurant.photos[photoIndex]} 
            alt={restaurant.name} 
            className="info-photo" 
          />
          <button onClick={nextPhoto} className="gallery-button gallery-button-right">{">"}</button>
        </div>
      )}
      <p className="info-address"><strong>Address:</strong> {restaurant.address || 'No address provided'}</p>
      <div className="add-review-button-container">
        <button className="add-review-button" onClick={handleAddReviewButton}>Add This Place</button>
        {loggedInUser && loggedInUser.role === 'admin' && (
        <button className="token-button" onClick={generateToken} disabled={isGeneratingToken}>
          {isGeneratingToken ? 'Generating Token...' : 'Generate Token'}
        </button>)}
        
      </div>
      {isReviewPanelOpen && (
        <AddReviewPanel
          restaurant={restaurant}
          onClose={handleCloseButton}
          onFileChange={onFileChange}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          loadingImg={loadingImg}
          onSave2={onSave}
          
        />
      )}

    </div>
  );
};

export default InfoWindowContent;