import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
`;

export const AppBarStyle = {
  position: 'absolute',
  width: '100%',
  zIndex: 1 
};

export const Container = styled.div`
  position: relative; // For absolute positioning of children
  height: 100vh;
  width: 100vw;
`;

export const SidebarStyle = {
  position: 'fixed',
  top: 75,
  left: 0,
  zIndex: 5,
  width: '400px',
  height: '52.5%',
  overflowY: 'auto',
  backgroundColor: '#fff',
  transition: 'left 0.3s ease-in-out',
  boxShadow: '2px 0 10px rgba(0,0,0,0.3)'
};

export const SearchBarStyle = styled.div`
  position: absolute;
  top: 8%; // Ajustez cette valeur selon la hauteur de votre NavBar
  left: -5px;
  width: 35%; // Ajustez selon la taille désirée
  z-index: 3; // Assurez-vous que la barre de recherche reste au-dessus de la carte
  box-shadow: 0 0px 0px rgba(0,0,0,0.3);
`;


export const InfoContentEditMap = (isSelectedPOI) => ({
    position: 'fixed',
    top: '15%',
    left: isSelectedPOI ? '1%' : '-30%',
    zIndex: 5,
    width: '30%',
    height: '90%',
    transition: 'left 0.3s ease-in-out',
    backgroundColor: '#fff',  
    borderRadius: '12px',      
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', 
    padding: 0,
    maxWidth: '100%',
    maxHeight: '60%',
    margin: '0 auto',
  });


  export const RestaurantWindowMainMap = (selectedRestaurant) => ({
    position: 'fixed',
    top: '15%',
    left: selectedRestaurant ? '1%' : '-35%',
    zIndex: 5,
    width: '35%',
    height: '90%',
    transition: 'left 0.3s ease-in-out',
    backgroundColor: '#fff',  
    borderRadius: '12px',      
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)', 
    padding: 0,
    maxWidth: '100%',
    maxHeight: '80%',
    margin: '0 auto',
  });
  

  export const mapContainerStyle = {
    height: 'calc(100vh)', 
    width: '100%',
  };
  
  export const messageStyle = {
    animation: 'fadeInOut 2s ease-in-out', 
    opacity: 1,  
    color: '#333',  
    textAlign: 'center',
    fontSize: '14px',  
    padding: '8px 20px',  
    position: 'absolute',
    top: '10%',  
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'auto',  
    backgroundColor: '#f8f8f8',  
    boxShadow: '0 2px 4px rgba(0,0,0,0.06)',  
    borderRadius: '4px',  
    zIndex: 1000
  };