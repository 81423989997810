import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { imagesFrontPage } from '../../config/path';
import ContactForm from './ContactForm';

// Global Styles to Reset Margin, Padding and Box Sizing
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden; // Prevent horizontal scrolling
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background: url(${imagesFrontPage.background}) center center / cover no-repeat fixed;
  width: 100vw; // Use vw unit to ensure it takes up exactly the width of the viewport
  overflow: hidden; // Ensures no spillover content causes scrolling
`;

const Navbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 50px; // Adjust size as needed
  width: auto;
  margin-right: 10px; // Adds some space between the image and text
`;

const LogoText = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: #F5F5DC; 
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
  color: #F5F5DC;
`;

const ContactFormPageDesktop = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar>
          <Logo onClick={() => handleNavigate('/')}>
            <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
            <LogoText>Dining with Kids</LogoText>
          </Logo>
          <NavLinks>
          </NavLinks>
        </Navbar>
        <ContactForm />
        <Footer>
          © {new Date().getFullYear()} Dining with Kids. All rights reserved.
        </Footer>
      </Container>
    </>
  );
};

export default ContactFormPageDesktop;
