import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { imagesFrontPage } from '../../config/path';
import ContactForm from './ContactFormMobile';
import  { GlobalStyle, Container, Navbar,  Logo, LogoImage, LogoText, Footer} from '../../styles/NavBarStyleMobile';

const ContactFormPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar>
          <Logo onClick={() => handleNavigate('/')}>
            <LogoImage src={imagesFrontPage.logo} alt="Dining with Kids Logo" />
            <LogoText>Dining with Kids</LogoText>
          </Logo>
        </Navbar>
        <ContactForm />
        <Footer>
          © {new Date().getFullYear()} Dining with Kids. All rights reserved.
        </Footer>
      </Container>
    </>
  );
};

export default ContactFormPage;
